import { template as template_b0b43a5328624a34872581e3c8a780c6 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_b0b43a5328624a34872581e3c8a780c6(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
