import { template as template_38114e0820204f2a8ed07c25cd106543 } from "@ember/template-compiler";
const FKLabel = template_38114e0820204f2a8ed07c25cd106543(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
